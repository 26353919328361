<template>
  <default-layout>
    <!-- ヘッダー -->
    <template #pageHeader>
      <PageHeader
        :title="title || defaultTitle"
        :subtitle="subtitle"
        :icon="headerIcon"
      >
        <div class="d-flex">
          <slot
            name="DetailMenus"
            :edit-mode="editMode"
            :object-name="objectName"
            :saved-page="savedPage"
            :list-page="listPage"
            :value="value"
            :can-edit="
              canEdit &&
              value.ErrataType__c !== '取消' &&
              !!$store.state.user.user.Permission__c
            "
          />
        </div>
      </PageHeader>
    </template>
    <v-alert v-if="value.ErrataType__c === '取消'" type="warning" class="my-3">
      このデータは下記理由によって取り消されています。
      <br />
      「{{ value.Errata__c }}」
    </v-alert>
    <slot
      name="underHeader"
      :edit-mode="editMode"
      :original-data="original.object"
      :is-fixed="original.object.IsFixed__c"
      :value="value"
    />
    <v-tabs v-model="tab" class="pt-2 cdsTabs">
      <v-tab>
        <v-icon left>
          mdi-card-bulleted
        </v-icon>
        詳細情報
      </v-tab>
      <v-tab v-if="!editMode && !disableAttachment">
        <v-icon left>
          mdi-paperclip
        </v-icon>
        添付ファイル
        <v-badge
          v-if="displayAttachments.length > 0"
          color="success"
          :content="displayAttachments.length"
          offset-x="0"
          offset-y="6"
          inline
        >
        </v-badge>
      </v-tab>
      <v-tab v-if="!editMode && useVersion">
        <v-icon left>
          mdi-history
        </v-icon>
        履歴
        <v-badge
          :value="!!tabHistoryCount"
          color="success"
          :content="tabHistoryCount"
          offset-x="0"
          offset-y="6"
          inline
        >
        </v-badge>
      </v-tab>
      <slot name="custom-tab" v-bind="{ editMode }" />
      <v-tabs-items v-model="tab" touchless>
        <v-tab-item>
          <v-form>
            <!-- 画像/動画表示部-->
            <v-carousel
              v-if="images.length !== 0"
              :show-arrows="images.length > 1"
              :hide-delimiters="images.length === 1"
              hide-delimiter-background
              show-arrows-on-hover
              class="my-3 d-print-none"
            >
              <v-carousel-item
                v-for="(image, i) in images"
                :key="i"
                reverse-transition="fade-transition"
                transition="fade-transition"
                :class="{
                  black: image.type === 'video',
                  grey: image.type === 'image',
                  'lighten-1': image.type === 'image',
                }"
              >
                <template #placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular indeterminate color="grey lighten-5" />
                  </v-row>
                </template>
                <template v-if="image.type === 'image'">
                  <v-img :src="image.src" contain class="fill-height" />
                </template>
                <template v-else-if="image.type === 'video'">
                  <div class="fill-height">
                    <video
                      controls
                      style="width: 100%; height: calc(100% - 50px);"
                    >
                      <source :src="image.src" />
                    </video>
                  </div>
                </template>
              </v-carousel-item>
            </v-carousel>
            <!-- 入力フォーム部 -->
            <slot
              name="layout"
              :value="value"
              :layout-name="layoutName"
              :object-name="objectName"
              :edit-mode="editMode"
              :skeleton-loading="skeletonLoading"
              :save="save"
              :validate="validate"
              :change="(val) => (value = val)"
              :original-data="original.object"
            >
              <Layout
                v-model="value"
                name="input_form"
                v-bind="{
                  layout,
                  hiddenSectionNames,
                  hiddenFieldNames,
                  readonlySectionNames,
                  readonlyFieldNames,
                  customValidations,
                  objectInfo,
                  editMode,
                  skeletonLoading,
                }"
                @submit-raw="validate"
                @change="handleChangeEditValue"
              >
                <template v-for="section in layout.sections" #[section.label]>
                  <slot
                    :name="section.label"
                    :value="value"
                    :edit-mode="editMode"
                    :change="(val) => (value = val)"
                  />
                </template>
                <template
                  v-for="section in layout.sections"
                  #[`${section.label}_bottom`]
                >
                  <slot
                    :name="section.label + '_bottom'"
                    :value="value"
                    :edit-mode="editMode"
                  />
                </template>
              </Layout>
            </slot>
          </v-form>
        </v-tab-item>
        <!-- 添付ファイル -->
        <v-tab-item v-if="!editMode">
          <v-data-table
            :items="displayAttachments"
            :headers="[
              { text: '', value: 'Download', sortable: false },
              { text: 'ファイル名', value: 'Name' },
              { text: 'ファイルサイズ', value: 'Size' },
              { text: 'ファイルタイプ', value: 'Type' },
              { text: '最終更新日', value: 'LastModifiedDate'},
            ]"
            class="elevation-0 cdsTable pt-3"
            :mobile-breakpoint="0"
            style="max-width: 800px;"
          >
            <template #item.Download="{ item }">
              <v-tooltip top :disabled="$vuetify.breakpoint.smAndDown">
                <template #activator="{ on: onTooltip }">
                  <v-btn
                    icon
                    small
                    :loading="!!item._isLoading"
                    v-on="{ ...onTooltip }"
                    @click="() => downloadItem(item)"
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </template>
                <span>ダウンロード</span>
              </v-tooltip>
              <v-tooltip top :disabled="$vuetify.breakpoint.smAndDown">
                <template #activator="{ on: onTooltip }">
                  <v-btn
                    icon
                    small
                    v-on="{ ...onTooltip }"
                    @click="() => copyURL(item)"
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                </template>
                <span>URLをコピー</span>
              </v-tooltip>
            </template>
            <template #item.Size="{ item }">
              {{ item.Size | filesize }}
            </template>
            <template #item.LastModifiedDate="{ item }">
              {{ formatDatetime(item.LastModifiedDate) }}
            </template>
          </v-data-table>
        </v-tab-item>
        <!-- 履歴 -->
        <v-tab-item v-if="!editMode && useVersion && value && value.Id" eager>
          <ListView
            v-if="useVersion"
            :search-props="{
              forceCondition: fixCondtion,
              canSearch: false,
            }"
            :table-props="{
              getRecordsMethodName: 'getFixRecords',
              listFieldSetName: 'FixListFieldSet,ListFieldSet',
              defaultListFieldSetName: 'FixListFieldSet,DefaultListFieldSet',
              canEdit: false,
              canCreate: false,
              defaultListOptions: {
                sortBy: ['Version__c'],
                sortDesc: [true],
              },
              applyMaxFields: true,
            }"
            v-bind="{
              objectName,
              listName: 'detail_history',
              detailLayoutName,
              detailPageName,
              hideTab: true,
              ...listViewProps,
            }"
            @load-completed="tabHistoryCount = $event.listData.length"
          >
            <template v-if="diffPage" #tab.compare>
              <v-icon left>
                mdi-call-split
              </v-icon>
              比較
            </template>
            <template #tabitem.compare>
              <Compare
                :object-name="objectName"
                get-records-method-name="getFixRecords"
                :default-search-condition="fixCondtion"
                field-set="CompareFieldSet"
              />
            </template>
          </ListView>
        </v-tab-item>
        <!-- コメント -->
        <slot name="custom-tab-item" v-bind="{ editMode }" />
      </v-tabs-items>
    </v-tabs>
    <v-dialog
      v-if="
        !editMode &&
        useVersion &&
        value &&
        value.Id &&
        Number(value.Version__c || 0) > 0
      "
      v-model="showCommentDialog"
      transition="dialog-bottom-transition"
      eager
      width="700"
      :fullscreen="isMobile"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          v-if="
            !editMode &&
            useVersion &&
            value &&
            Number(value.Version__c || 0) > 0
          "
          v-bind="attrs"
          color="primary"
          right
          fixed
          :style="{
            bottom: $vuetify.breakpoint.smAndDown ? '74px' : '160px',
            'z-index': 3,
          }"
          class="d-print-none"
          v-on="on"
          @click="showCommentDialog = false"
        >
          コメント
          <v-icon small class="pl-2 pr-1">
            mdi-comment-text-multiple
          </v-icon>
          {{ tabCommentCount }}
        </v-btn>
      </template>
      <Comment
        @updated-comments="tabCommentCount = $event.length"
        @showCommentDialog="showCommentDialog = $event"
      />
    </v-dialog>
    <v-dialog v-model="showDataCancelModal" max-width="1024">
      <v-form ref="errataForm" lazy-validation>
        <v-card class="mx-auto" max-width="1024" outlined>
          <v-card-title>
            <v-icon left color="error">
              mdi-alert
            </v-icon>
            取消
          </v-card-title>
          <v-card-text class="text--primary">
            <div class="mb-2">
              <div>
                取消は「訓練のつもりだったのに実災害で登録してしまった」等の誤報または誤操作に対して情報自体を無かったことにする場合に行います。
              </div>
              <div>
                取消し後はこの情報を変更できなくなります。登録されている続報も含めて取り消されます。取消を行う場合は理由を記入してください。
              </div>
              <slot name="cancel-dialog-message">
                <div>
                  <span class="red--text">
                    ※FUJISAN連携している場合、取消操作を行ってもFUJISAN上は取り消しされません。
                  </span>
                </div>
              </slot>
            </div>
            <v-textarea
              v-model="value.Errata__c"
              :rules="[(v) => !!v || '取消理由は必須項目です。']"
              label="取消理由"
              outlined
              dense
              hide-details="auto"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn class="ml-4" text @click="showDataCancelModal = false">
              キャンセル
            </v-btn>
            <v-btn color="error" class="ml-4" depressed @click="cancelData">
              <v-icon left> mdi-delete-forever </v-icon>取消
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- FileInput(非表示) -->
    <input
      ref="fileInput"
      type="file"
      style="display: none;"
      multiple="multiple"
      @change="onFileChange"
    />
    <!--添付ファイル-->
    <AttachmentUpload
      :show-uploader-modal="showUploaderModal"
      :attachment-uploading="attachmentUploading"
      :attachments="attachments"
      @show="(val) => (showUploaderModal = val)"
      @add-attachment="$refs.fileInput.click()"
      @remove-attachment="removeAttachment"
      @dropped-files="handleDroppedFiles"
    />
    <template #outsideContent>
      <DetailMenus
        :can-edit="
          canEditComputed &&
          value.ErrataType__c !== '取消' &&
          !!$store.state.user.user.Permission__c
        "
        :reference-only="referenceOnly"
        :use-version="useVersion"
        :is-fixed="original.object.IsFixed__c"
        :delete-text="alreadyFixed ? '取消' : '削除'"
        :fix-message="fixMessage"
        :fix-text="fixText"
        :edit-mode="editMode"
        :is-deleted="IsDeleted"
        :hidden="hideMenu"
        v-bind="{ disable: disableActions }"
        @save="$formulate.submit('input_form')"
        @add-attachment="showUploaderModal = true"
        @cancel="changeEditMode(false)"
        @remove="remove()"
        @edit="changeEditMode(true)"
        @fix="fix()"
        @next-version="$transition.to(`${savedPage}?id=${ParentId__c}&e=true`)"
      />
    </template>
  </default-layout>
</template>

<script>
import defaultLayout from '@/components/layout/default';
import PageHeader from '@/components/app/PageHeader';
import AttachmentUpload from '@/components/detail/AttachmentUpload';
import Layout from '@/components/detail/Layout';
import DetailMenus from '@/components/detail/DetailMenus';
import ListView from '@/components/list/ListView';
import Compare from '@/components/detail/Compare/index';
import Comment from './comment';
import { getUrl } from '@/assets/js/s3.js';
import { format as formatTZ, utcToZonedTime } from 'date-fns-tz';

import * as util from './util';
import { mapActions } from 'vuex';

export default {
  components: {
    defaultLayout,
    PageHeader,
    AttachmentUpload,
    Layout,
    DetailMenus,
    ListView,
    Compare,
    Comment,
  },
  // 依存性の注入
  provide() {
    const reactive = {};
    Object.defineProperty(reactive, 'value', {
      enumerable: true,
      get: () => this.value,
    });
    Object.defineProperty(reactive, 'ParentId__c', {
      enumerable: true,
      get: () => this.ParentId__c,
    });
    Object.defineProperty(reactive, 'latest', {
      enumerable: true,
      get: () => this.latest,
    });
    return { reactive, objectName: this.objectName };
  },
  props: {
    // オブジェクト名
    objectName: { type: String, required: true },
    // 新規作成時ヘッダタイトル
    defaultTitle: { type: String, required: true },
    // 表示レイアウト名
    layoutName: { type: String, required: true },
    // 非表示セクション名
    defaultHiddenSectionNames: {
      type: Array,
      required: false,
      default: () => [],
    },
    // 非表示フィールド名
    defaultHiddenFieldNames: {
      type: Array,
      required: false,
      default: () => [],
    },
    // 読み取り専用セクション名
    defaultReadonlySectionNames: {
      type: Array,
      required: false,
      default: () => [],
    },
    // 読み取り専用フィールド名
    defaultReadonlyFieldNames: {
      type: Array,
      required: false,
      default: () => [],
    },
    // 入力チェック拡張
    customValidations: { type: Array, required: false, default: () => [] },
    // ヘッダサブタイトル
    subtitle: { type: String, required: false, default: '' },
    // ヘッダアイコン
    headerIcon: { type: String, required: false, default: '' },
    // 保存後の遷移ページ
    savedPage: { type: String, required: true, default: '' },
    // 一覧ボタンクリック時の遷移ページ
    listPage: { type: String, required: false, default: '' },
    // バージョン管理有無
    useVersion: { type: Boolean, required: false },
    // 履歴遷移時ページ名
    detailPageName: { type: String, required: false, default: '' },
    // 履歴詳細参照レイアウト名
    detailLayoutName: { type: String, required: false, default: '' },
    // 履歴検索レイアウト名
    searchLayoutName: { type: String, required: false, default: '' },
    //差分一覧ベッジ表示非表示
    diffPage: { type: Boolean, default: false },
    //被害ペッジ確認
    // 一覧コンポーネントへのbind
    listViewProps: { type: Object, default: () => ({}) },
    // 新規作成時初期値
    initValue: { type: Object, required: false, default: () => ({}) },
    // 編集時上書き初期値 * 詳細はhttp://10.10.2.188:8929/shizuoka-city-bousai/city.shizuoka.bousai/issues/287
    everyOverwriteValue: { type: Object, required: false, default: () => ({}) },
    // マスタ読み出し関数
    loadMaster: { type: Function, required: false, default: () => ({}) },
    // 各種操作オーバライド関数群
    override: { type: Object, required: false, default: () => ({}) },
    // ヘッダボタン制御
    disableActions: { type: Array, required: false, default: () => [] },
    // 編集モードの操作を親から行うか
    useCustomEditMode: { type: Boolean, default: false },
    // 編集モード
    customEditMode: { type: Boolean, default: false },
    // 添付を行わない
    disableAttachment: { type: Boolean, default: false },
    // タイトルとして表示する場合のオブジェクトのフィールド名
    titleFieldName: { type: String, default: 'Name' },
    // 訂正項目の自動設定等を利用するか(自動で訂正をクリアする処理を入れるか)
    useCorrection: { type: Boolean, default: false },
    // 確定ボタンとメッセージ
    fixMessage: { type: String, required: false, default: undefined },
    fixText: { type: String, required: false, default: undefined },
    // メニューを隠す
    hideMenu: { type: Boolean, default: false },
  },
  data: () => ({
    // 表示値
    value: {},
    // DBから読みだした未変更の値
    original: { object: { IsFixed__c: false }, attachments: [] },
    // 最新報のデータ
    latest: undefined,
    // 編集モード
    editMode_: false,
    // 編集可否
    canEdit: true,
    // ローディング
    skeletonLoading: true,
    // アップロード用モーダル表示
    showUploaderModal: false,
    // 添付ファイルアップロード中
    attachmentUploading: false,
    // 添付ファイルリスト
    attachments: [],
    // 削除対象添付ファイルリスト
    deleteAttachment: [],
    // version0のID
    ParentId__c: '',
    // 表示レイアウト情報
    layout: {},
    // 表示オブジェクト情報
    objectInfo: {},
    // 取り消しモーダル表示
    showDataCancelModal: false,
    // コメントダイアログ表示
    showCommentDialog: false,
    // タブ
    tab: null,
    // 履歴件数
    tabHistoryCount: null,
    // コメント件数
    tabCommentCount: null,
    // 非表示セクション名
    hiddenSectionNames: [],
    // 非表示フィールド名
    hiddenFieldNames: [],
    // 読み取り専用セクション名
    readonlySectionNames: [],
    // 読み取り専用フィールド名
    readonlyFieldNames: [],
    // ページヘッダーのアイテム
    menuItem: null,
  }),
  computed: {
    // 編集モード
    editMode: {
      get() {
        if (this.useCustomEditMode) return this.customEditMode;
        return this.editMode_;
      },
      set(val) {
        this.editMode_ = val;
        if (val) {
          // デフォルト値設定
          if (this.everyOverwriteValue) {
            this.value = {
              ...this.value,
              ..._.cloneDeep(this.everyOverwriteValue),
            };
          }
          // 訂正をクリアする
          if (this.useCorrection) {
            // 確定後初めての編集時のみ実施
            if (this.original?.object?.IsFixed__c) {
              // 区分のクリア
              this.value.ErrataType__c = null;
              // テキストのクリア
              this.value.Errata__c = null;
            }
          }
        } else {
          // 編集キャンセル時
          // 新規作成時
          if (!this.original.object.Id) {
            this.$transition.back();
          }
          // 編集時
          else {
            this.value = this.original.object;
            this.attachments = this.original.attachments;
            this.deleteAttachment = [];
          }
        }
      },
    },
    // 編集可能
    canEditComputed() {
      // ユーザの権限
      const userPermissionList =
        this.$store.state.user.user.Permission__c?.split(';')?.filter(
          (v) => !!v,
        ) || [];

      // ページの権限
      const pagePermissionList =
        this.menuItem?.Permission__c?.split(';')?.filter((v) => !!v) || [];

      // ページで指定された権限をユーザが持っている時のみ許可
      return (
        this.canEdit &&
        (pagePermissionList.length === 0 ||
          pagePermissionList.some((v) => userPermissionList.includes(v)))
      );
    },
    // 添付ファイル表示用
    displayAttachments() {
      return this.attachments;
    },
    // 画像・動画一覧整形データ
    images() {
      return this.attachments
        .filter(({ url }) => url)
        .filter(({ Type }) => /[image|video]\/.*/.test(Type))
        .map((d) => ({
          type: d.Type.split('/')[0],
          src: d.url,
        }));
    },
    // ヘッダ部表示タイトル
    title() {
      return this.value[this.titleFieldName];
    },
    // 確定報表示用検索条件
    fixCondtion() {
      return {
        IsFixed__c: true,
        Version__c: { gt: 0 },
        ParentId__c: this.ParentId__c,
      };
    },
    // 参照のみ
    referenceOnly() {
      // バージョン管理化でversion0以外のものは続報登録ボタンしか表示させない
      if (this.useVersion && this.value.Version__c != 0 && this.value.Id)
        return true;
      return false;
    },
    /**
     * 一度でも確定していればtrue
     */
    alreadyFixed() {
      const latest = this.original.object[this.childRelationFieldName];
      return this.useVersion && !_.isEmpty(latest);
    },
    /**
     * 自身にリレーションしている項目名
     */
    childRelationFieldName() {
      if (!this.objectInfo?.properties) return null;
      const [fieldName] =
        Object.entries(this.objectInfo.properties).find(([, value]) => {
          return (
            !value.relationField &&
            value.items?.$ref === `#/definitions/${this.objectName}`
          );
        }) || [];
      return fieldName || null;
    },
    /**
     * 削除
     */
    IsDeleted() {
      return !!this.original?.object?.IsDeleted;
    },
    // モバイル判定
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  async mounted() {
    this.skeletonLoading = true;
    await Promise.all([
      this.loadData(),
      this.loadLayout(),
      this.loadMenuItem(),
    ]);
    this.skeletonLoading = false;
  },
  methods: {
    async loadLayout() {
      const { controller } = this.$pageProperty;
      const { layoutName, objectName, $con } = this;
      const { layout, objectInfo, initVal } = await util.getLayout({
        $con,
        layoutName,
        objectName,
        controller,
      });
      this.layout = layout;
      this.objectInfo = objectInfo;
      this.hiddenSectionNames = [
        ...this.hiddenSectionNames,
        ...this.defaultHiddenSectionNames,
      ];
      this.hiddenFieldNames = [
        ...this.hiddenFieldNames,
        ...this.defaultHiddenFieldNames,
      ];
      this.readonlySectionNames = [
        ...this.readonlySectionNames,
        ...this.defaultReadonlySectionNames,
      ];
      this.readonlyFieldNames = [
        ...this.readonlyFieldNames,
        ...this.defaultReadonlyFieldNames,
      ];
      if (this.editMode) this.value = { ...initVal, ...this.value };
    },
    async loadMenuItem() {
      this.menuItem = await this.$util.getPageHeaderMenuItem();
    },
    /**
     * データ読み出し
     */
    async loadData() {
      const { objectName } = this;
      const { controller } = this.$pageProperty;
      const query = this.$query.current();
      // デフォルト値設定
      if (this.initValue) {
        this.value = _.cloneDeep(this.initValue);
      }
      // マスタデータ読み出し用関数が定義されていれば設定
      if (typeof this.loadMaster === 'function') {
        const master = await this.loadMaster();
        util.deleteIdColumn(master);
        // arcgis連携の項目を削除
        delete master.GlobalId__c;
        delete master.ObjectId__c;
        delete master.Success__c;
        this.value = { ...master, ...this.value };
      }
      if (query.id) {
        try {
          // データロード
          const { object, attachments, latest } = await this.$con.invoke({
            controller,
            method: 'getData',
            params: {
              id: query.id,
              objectName,
            },
          });
          this.latest = latest;
          // バージョン判定
          if (this.useVersion) {
            this.ParentId__c =
              Number(object.Version__c) === 0 ? object.Id : object.ParentId__c;
          }
          // オリジナルデータの保持
          this.$set(this, 'original', {
            object,
            attachments: attachments.map((d) => ({
              ...d,
              // 拡張子のmimeタイプが保持されないので、概要に入れておく
              type: d.Description,
            })),
          });
          this.attachments = this.original.attachments;
          await Promise.all(
            this.attachments.map(async (d) => {
              d.url = await getUrl(d.FileId);
            }),
          );
          this.attachments = [...this.attachments];
          this.value = object;
          // パラメータ指定による編集モード
          if (query.e === 'true') this.editMode = true;
          this.$emit('loaded-data', this.value);
        } catch (error) {
          this.openErrorSnackBar({
            message: 'データの読み出しに失敗しました。' + error.message,
          });
          console.error(error);
        }
      } else {
        this.canEdit = true;
        this.editMode = true;
        await this.$store.dispatch('detail/setEditing', this.editMode);
      }
      // 初報作成時は訂正セクションを非表示にする
      if (!this.value.Id) {
        this.hiddenSectionNames.push('訂正');
      }
    },
    /**
     * 編集モード変更(loading表示)
     */
    async changeEditMode(newMode) {
      await this.$store.dispatch('loading/setForce', true);
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 2);
      });
      this.editMode = newMode;
      await this.$store.dispatch('loading/setForce', false);
      await this.$store.dispatch('detail/setEditing', this.editMode);
    },
    /**
     * 入力チェック
     */
    async validate(form) {
      try {
        // フォーム入力値に不正がないかチェック
        if (await form.hasValidationErrors()) {
          throw new Error('入力内容にエラーがあります.');
        }
        // 追加のチェックがあれば実施する(問題があればエラーを投げる)
        await this.override?.validate?.bind(this)({ value: this.value, form });

        await this.save();
      } catch (error) {
        this.saveFail(error.message);
      }
    },
    /**
     * データ保存
     */
    async save() {
      try {
        let res = {};
        // 保存ロジックオーバライド
        if (typeof this.override.save === 'function') {
          res = await this.override.save.bind(this)();
        } else {
          const { objectName } = this;
          const { controller } = this.$pageProperty;
          const newData = {
            ...this.original.object,
            ...this.value,
            attributes: { type: objectName },
          };
          // バージョン利用時は保存のタイミングで確定を外す
          if (this.useVersion) {
            newData.IsFixed__c = false;
          }

          // データ保存
          res = await this.$store.dispatch(
            'loading/register',
            this.$con.invoke({
              controller,
              method: 'saveData',
              params: {
                object: JSON.stringify(newData),
              },
            }),
          );
          if (res.errors) {
            throw new Error('サーバエラー:' + res.errors?.message);
          }
          // 添付ファイル保存
          await this.saveAttachment(res.obj.Id);
        }
        this.saveComplete();
        this.editMode = false;
        this.deleteAttachment = [];
        this.$transition.to(
          this.savedPage,
          {
            id: res.obj.Id,
            sb: JSON.stringify({
              message: '保存しました',
              props: {
                color: 'green',
                bottom: true,
                timeout: 2000,
              },
              closable: true,
            }),
          },
          { keepRetUrl: true },
        );
      } catch (e) {
        console.error(e);
        this.saveFail(e.message);
      }
    },
    /**
     * 添付ファイルを保存・削除する
     */
    async saveAttachment(objId) {
      const { controller } = this.$pageProperty;
      // 添付ファイル保存
      if (this.attachments.length > 0) {
        this.attachmentUploading = true;
        this.showUploaderModal = true;
        await this.attachmentUploader.start(
          this.attachments.filter(({ Id }) => !Id),
          objId,
          this.objectName,
        );
        this.showUploaderModal = false;
        this.attachmentUploading = false;
      }
      // 添付ファイル削除
      if (this.deleteAttachment.length > 0) {
        console.log(this.deleteAttachment);
        const targets = this.deleteAttachment.map(({ Id }) => Id);
        await this.$store.dispatch(
          'loading/register',
          this.$con.invoke({
            controller,
            method: 'deleteAttachments',
            params: { targets: JSON.stringify(targets), objId },
          }),
        );
      }
    },
    /**
     * 確定
     */
    async fix() {
      try {
        const { objectName } = this;
        const { controller } = this.$pageProperty;
        const fixData = {
          attributes: { type: objectName },
          ...this.original.object,
          ...this.value,
        };
        await this.$store.dispatch(
          'loading/register',
          this.$con.invoke({
            controller,
            method: 'fixRecord',
            params: {
              object: JSON.stringify(fixData),
            },
          }),
        );
        // this.saveComplete();
        this.$transition.to(this.savedPage, {
          ...this.$query.current(),
          sb: JSON.stringify({
            message: '確定しました',
            props: {
              color: 'green',
              bottom: true,
              timeout: 2000,
            },
            closable: true,
          }),
        });
      } catch (e) {
        console.error(e);
        this.saveFail(e.message);
      }
    },
    /**
     * 削除,取り消し
     */
    async remove() {
      if (typeof this.override.remove === 'function') {
        await this.override.remove.bind(this)();
        return;
      }
      const { objectName } = this;
      const { controller } = this.$pageProperty;
      try {
        if (this.alreadyFixed) {
          // バージョン管理下の場合
          // 取り消し理由入力モーダルを表示
          // 実際の取り消し処理はcancelDataに記述
          this.showDataCancelModal = true;
        } else {
          // バージョン管理下で０報のみの場合はそのままレコード削除
          // バージョン管理外の場合はそのままレコード削除
          await this.$store.dispatch(
            'loading/register',
            this.$con.invoke({
              controller,
              method: 'deleteData',
              params: {
                object: JSON.stringify({
                  attributes: { type: objectName },
                  Id: this.original.object.Id,
                }),
              },
            }),
          );
          // 添付ファイル削除
          if (this.attachments.length > 0) {
            console.log(this.attachments);
            const targets = this.attachments.map(({ Id }) => Id);
            await this.$store.dispatch(
              'loading/register',
              this.$con.invoke({
                controller,
                method: 'deleteAttachments',
                params: {
                  targets: JSON.stringify(targets),
                  objId: this.original.object.Id,
                },
              }),
            );
          }
          const query = this.$query.current();
          delete query.id;
          this.$transition.to(this.listPage || this.$transition.fallbackPage, {
            ...query,
            sb: JSON.stringify({
              message: '削除しました',
              props: {
                color: 'green',
                bottom: true,
                timeout: 2000,
              },
              closable: true,
            }),
          });
        }
      } catch (e) {
        console.error(e);
        this.saveFail(e.message);
      }
    },
    /**
     * データ取消処理
     */
    async cancelData() {
      // formのvalidateをかける
      const validateRes = this.$refs.errataForm.validate();
      // 取消理由が入っていない場合はその先の処理を行わない
      if (!validateRes) return;

      const { objectName } = this;
      const { controller } = this.$pageProperty;
      this.value.ErrataType__c = '取消';

      const cancelData = {
        attributes: { type: objectName },
        ...this.original.object,
        ...this.value,
      };

      await this.$store.dispatch(
        'loading/register',
        this.$con.invoke({
          controller,
          method: 'cancelData',
          params: {
            object: JSON.stringify(cancelData),
          },
        }),
      );
      this.$transition.to(this.listPage || this.savedPage, {
        ...this.$query.current(),
        sb: JSON.stringify({
          message: '取り消しました',
          props: {
            color: 'green',
            bottom: true,
            timeout: 2000,
          },
          closable: true,
        }),
      });
    },
    /**
     * 添付ファイルをドロップ
     */
    handleDroppedFiles(files) {
      this.onFileChange({
        target: {
          files,
        },
      });
    },
    /**
     * 添付ファイル選択
     */
    onFileChange({ target }) {
      const _attachments = [...target.files].map((d) => {
        const ret = {
          file: d,
          progres: null,
          uploadComplete: false,
        };
        ret.progresEvent = (ev) => {
          ret.progres = (ev.loaded / ev.total) * 100;
        };
        return ret;
      });

      this.attachments = [...this.attachments, ..._attachments];
    },
    /**
     * 添付ファイル除外
     */
    removeAttachment(index) {
      let next = [...this.attachments];
      const del = _.pullAt(next, index)[0];
      // すでにアップロード済みの添付ファイルを削除した場合
      if (del.Id) {
        this.deleteAttachment.push(del);
      }
      this.attachments = next;
    },
    ...mapActions('snackbar', [
      'saveComplete',
      'saveFail',
      'openSnackBar',
      'openErrorSnackBar',
    ]),
    /**
     * 編集中の値が変更した場合のイベント処理
     */
    handleChangeEditValue(event) {
      if (typeof this?.override?.change === 'function') {
        this.override.change.bind(this)(event);
      }
    },
    /**
     * ダウンロードを行う
     */
    async downloadItem(item) {
      try {
        this.$set(item, '_isLoading', true);
        const url = await getUrl(item.FileId);
        if (url) {
          const blob = await fetch(url).then((r) => r.blob());

          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = item.Name;
          this.$set(item, '_isLoading', false);
          link.click();
        }
      } catch (error) {
        this.openErrorSnackBar({
          message: 'ダウンロードに失敗しました。' + error.message,
        });
      }
      this.$set(item, '_isLoading', false);
    },
    /**
     * URLをクリップボードにコピー
     */
    async copyURL(item) {
      const url =
        location.protocol +
        '//' +
        location.host +
        '/CDS_VF_Attachment?id=' +
        item.Id;
      console.log(url);
      try {
        await this.$copyText(url);
        this.saveComplete('ダウンロード用URLをコピーしました。');
      } catch (e) {
        this.openErrorSnackBar({
          message: 'クリップボードへのコピーが失敗しました。' + e.message,
        });
      }
    },
    /**
     * 日付をフォーマットする
     */
    formatDatetime(d) {
      if (!d) return;
        const jstDate = utcToZonedTime(d, 'Asia/Tokyo');
        return formatTZ(jstDate, "yyyy-MM-dd HH:mm:ss", {
          timeZone: 'Asia/Tokyo',
        });
    },
  },
};
</script>
<style>
.v-carousel__controls {
  z-index: unset !important;
}
</style>
